// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-apps-js": () => import("./../src/pages/apps.js" /* webpackChunkName: "component---src-pages-apps-js" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-breadbox-js": () => import("./../src/pages/breadbox.js" /* webpackChunkName: "component---src-pages-breadbox-js" */),
  "component---src-pages-breadbox-privacy-js": () => import("./../src/pages/breadbox-privacy.js" /* webpackChunkName: "component---src-pages-breadbox-privacy-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-push-it-privacy-js": () => import("./../src/pages/push-it-privacy.js" /* webpackChunkName: "component---src-pages-push-it-privacy-js" */),
  "component---src-pages-pushit-js": () => import("./../src/pages/pushit.js" /* webpackChunkName: "component---src-pages-pushit-js" */),
  "component---src-pages-waker-js": () => import("./../src/pages/waker.js" /* webpackChunkName: "component---src-pages-waker-js" */),
  "component---src-pages-waker-privacy-js": () => import("./../src/pages/waker-privacy.js" /* webpackChunkName: "component---src-pages-waker-privacy-js" */),
  "component---src-templates-blog-js": () => import("./../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */)
}

